@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "resources/assets/sass/_variables.scss";

.alert {
    width:100%;
}

html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px;
}

    
footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-top:20px;
    height: 60px;
    background-color: #f5f5f5;
}

.mt-25 {
    margin-top:25px;
}


.table-responsive {
    @media screen and (max-width: 767px){
        border:none;
    }
}

body#private {

    .table {
      > tbody {
        > tr {
          > th,
          > td {
            vertical-align: middle;
          }
        }
      }
    }
    
    .sortable .fa{
        padding:5px;
    }
        
    .table > thead > tr > th, .table > thead > tr > th a {
        background: $brand-primary;
        color:$panel-primary-text;
    }
    
    .left-vertical-sep {
        border-left: 1px solid #efefef;
    }
     
    .tab-pane{
        margin-top:15px;
    }
     
     .right-sticky{
        position: fixed;
        top:80px;
        right:0px;
     }
     
    .panel{
        padding:0;
     }
     
     .spacer{
        clear:both;
        margin:5px;
     }
     
     .btn-group{
        margin:5px;
    }
    
    .page-header h1{
        text-align:center;
    } 
    .page-header{
        margin-top:70px;
        color:$brand-primary;
     }
    
    .bg-row, .table-striped > tbody > tr.bg-row:nth-of-type(odd){
        color: $state-info-text;
        background:$state-info-bg;
    }
    
    .navbar-inverse {
        background: $brand-primary;
        border-color:darken($brand-primary, 10%);
    }
        
    .navbar:before {
        background-image: linear-gradient(rgba(255,255,255,0.12), rgba(255,255,255,0));
        box-shadow: none;
        content: "";
        height: 51px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    
    .navbar-inverse .navbar-nav > li > a:hover,
    .navbar-inverse .navbar-nav > li > a.active,
    .navbar-inverse .navbar-nav > .active > a, 
    .navbar-inverse .navbar-nav > .active > a:hover, 
    .navbar-inverse .navbar-nav > .active > a:focus,    
    .navbar-inverse .navbar-nav > .open > a, 
    .navbar-inverse .navbar-nav > .open > a:hover, 
    .navbar-inverse .navbar-nav > .open > a:focus {
        background: darken($brand-primary, 10%);
    }
    
    .navbar-inverse .navbar-nav > li > a, a.navbar-brand {
        color:#fff;
    }
    
    h1, h2, h3, h4, h5, h6{
        color:#595959;
     }
     
     .btn.btn-primary.pull-right{
        margin-top:20px;
     }
     
    .stat-circle{
        font-size:30px;
        width:100px;
        height:100px;
        padding-top:30px;
        text-align:center;
        color:#fff;
        margin:0 auto;
     }
     
     .btn-go {
        padding:10px 30px 10px 30px;
        margin-top:20px;
     }
     
     .left-border{
        border-left:1px solid #d5d5d5;
     }
     
     .percent-20{
        width:20%;
     }
     
     .pt-50{
       padding-top:50px;
     }
     
     .pb-50{
        padding-bottom:50px;
     }
    
    h4 strong.col-md-1 {
        padding-left:0;
     }
}
 

body#public {
    background-color: #eee;
    
    .alert{
        text-align:center;
    }
     
     .form-signin {
          max-width: 330px;
          padding: 15px;
          margin: 0 auto;
          text-align:center;
    }
     
    .form-signin .form-signin-heading{
            font-size: 60px;
            color: rgb(51, 122, 183);
    }
        
    .form-signin .form-signin-heading span{
            font-size:16px;
    }
        
    .form-signin .form-signin-heading,
    .form-signin .checkbox {
          margin-bottom: 10px;
    }
        
    .form-signin .checkbox {
          font-weight: normal;
    }
        
    .form-signin .form-control {
          position: relative;
          height: auto;
          -webkit-box-sizing: border-box;
             -moz-box-sizing: border-box;
                  box-sizing: border-box;
          padding: 10px;
          font-size: 16px;
          margin-top:15px;
    }
        
    .form-signin .form-control:focus {
          z-index: 2;
    }
    
    .form-signin input[type="email"] {
          margin-bottom: -1px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
    }
        
    .form-signin input[type="password"] {
          margin-bottom: 10px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
    }
}